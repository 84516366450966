import React, { useState } from "react";

export default function DemoCanvas() {
  // State for each step's input and result
  const [textInput, setTextInput] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [finalVideo, setFinalVideo] = useState(null);

  // State for tracking progress
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  // Simulated API Call for each step (replace with actual API calls)
  const generateImage = async () => {
    setLoading(true);
    // Simulate an API call
    setTimeout(() => {
      setImage("https://via.placeholder.com/150"); // Replace with real image URL from API response
      setLoading(false);
      setCurrentStep(2);
    }, 2000);
  };

  const generateVideo = async () => {
    setLoading(true);
    setTimeout(() => {
      setVideo("https://via.placeholder.com/150.mp4"); // Replace with real video URL from API response
      setLoading(false);
      setCurrentStep(3);
    }, 2000);
  };

  const addLipSync = async () => {
    setLoading(true);
    setTimeout(() => {
      setFinalVideo("https://via.placeholder.com/150-lipsync.mp4"); // Replace with real final video URL from API response
      setLoading(false);
    }, 2000);
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold text-center mb-6">Demo Canvas</h2>

      {/* Step 1: Text to Image */}
      {currentStep >= 1 && (
        <div className="mb-8 p-4 border border-gray-400 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold">Step 1: Text to Image</h3>
          <p>Enter the text to generate an image.</p>
          <input
            type="text"
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            className="border p-2 rounded mt-2 w-full"
            placeholder="Enter text here..."
            disabled={loading || currentStep > 1}
          />
          <button
            onClick={generateImage}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            disabled={loading || !textInput}
          >
            {loading ? "Generating Image..." : "Generate Image"}
          </button>
          {image && (
            <img src={image} alt="Generated" className="mt-4 rounded" />
          )}
        </div>
      )}

      {/* Step 2: Image to Video */}
      {currentStep >= 2 && (
        <div className="mb-8 p-4 border border-gray-400 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold">Step 2: Image to Video</h3>
          <p>Use the generated image to create a video.</p>
          {image && (
            <img
              src={image}
              alt="For Video Generation"
              className="mt-4 rounded"
            />
          )}
          <button
            onClick={generateVideo}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            disabled={loading || !image}
          >
            {loading ? "Generating Video..." : "Generate Video"}
          </button>
          {video && (
            <video controls className="mt-4 rounded">
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}

      {/* Step 3: Add Lip Sync */}
      {currentStep >= 3 && (
        <div className="mb-8 p-4 border border-gray-400 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold">Step 3: Add Lip Sync</h3>
          <p>Apply lip sync to the generated video.</p>
          {video && (
            <video controls className="mt-4 rounded">
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <button
            onClick={addLipSync}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            disabled={loading || !video}
          >
            {loading ? "Adding Lip Sync..." : "Add Lip Sync"}
          </button>
          {finalVideo && (
            <video controls className="mt-4 rounded">
              <source src={finalVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}

      {/* Completion Message */}
      {finalVideo && (
        <div className="p-4 border border-green-500 rounded-lg shadow-md bg-green-50">
          <h3 className="text-xl font-semibold text-green-600">
            Process Complete!
          </h3>
          <p className="text-green-600">
            The final video with lip sync has been generated successfully.
          </p>
        </div>
      )}
    </div>
  );
}
