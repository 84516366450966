import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import ShimmerButton from "./ShimmerButton";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleJoinBetaClick = (e) => {
    e.preventDefault();
    navigate("/", { replace: true });
    setTimeout(() => {
      const contactForm = document.getElementById("contact-form");
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
    closeMenu();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="bg-gray-900 flex justify-center py-4 w-auto sticky top-0 z-50">
      <header className="relative bg-slate-500 text-black font-bold font-poppins shadow-lg mx-0 my-0 rounded-full w-screen max-w-screen-2xl ml-5 mr-5">
        <div className="flex justify-between items-center py-4 px-5">
          {/* Logo */}
          <div className="flex items-center font-poppins font-bold text-black text-3xl">
            <Link to="/" className="flex items-center ml-20 md:ml-24">
              <img
                src="/images/mindrllogo.png"
                alt="Mindlr Logo"
                className="h-8 w-auto mr-2"
              />
              Mindlr
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-2xl mt-1">
              {isOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Mobile Dropdown Menu */}
          <nav
            ref={menuRef}
            className={`${
              isOpen ? "flex" : "hidden"
            } fixed top-24 left-0 w-full h-auto bg-slate-800 text-white flex-col items-center justify-center z-50 transition-transform duration-300 ease-in-out rounded-b-2xl space-y-6`}
          >
            <Link
              to="/sign-in"
              className="block mt-2 text-lg font-poppins hover:underline"
              onClick={closeMenu}
            >
              Sign In
            </Link>
            <Link
              to="/blog"
              className="block mt-2 text-lg font-poppins hover:underline"
              onClick={closeMenu}
            >
              Blog
            </Link>
            <a
              href="#contact-form"
              className="block mt-2 text-xl font-poppins"
              onClick={handleJoinBetaClick}
            >
              <ShimmerButton className="text-lg font-poppins">
                Join Beta
              </ShimmerButton>
            </a>
          </nav>

          {/* Desktop Menu */}
          <nav className="hidden md:flex md:items-center space-x-8">
            <Link to="/sign-in" className="hover:underline text-lg font-medium">
              Sign In
            </Link>
            <Link to="/blog" className="hover:underline text-lg font-medium">
              Blog
            </Link>
            <a href="#contact-form" onClick={handleJoinBetaClick}>
              <ShimmerButton className="text-lg mr-24 font-poppins">
                Join Beta
              </ShimmerButton>
            </a>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
