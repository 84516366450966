import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const WordPullUp = ({
  words,
  wrapperFramerProps = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  },
  framerProps = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  },
  className,
}) => {
  const ref = useRef(null);
  const controls = useAnimation(); // Use framer-motion's useAnimation hook
  const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has already occurred

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          controls.start("show"); // Start animation when in view
          setHasAnimated(true); // Ensure it doesn't animate again
        }
      },
      { threshold: 0.5 }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [controls, hasAnimated]);

  return (
    <motion.h1
      ref={ref}
      initial="hidden"
      animate={controls} // Use controls to handle animation state
      variants={wrapperFramerProps}
      className={`font-display text-center text-4xl font-bold leading-[5rem] tracking-[-0.02em] drop-shadow-sm ${className}`}
    >
      {words.split(" ").map((word, i) => (
        <motion.span
          key={i}
          variants={framerProps}
          style={{ display: "inline-block", paddingRight: "8px" }}
        >
          {word === "" ? <span>&nbsp;</span> : word}
        </motion.span>
      ))}
    </motion.h1>
  );
};

export default WordPullUp;
