// Marquee.js
import React from "react";

const Marquee = ({
  reviews,
  reverse = false,
  className = "",
  pauseOnHover = false,
}) => {
  return (
    <div
      className={`group flex overflow-hidden p-2 [--duration:20s] [--gap:1rem] ${className}`}
    >
      {Array(4) // Set how many times the marquee will loop
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={`flex shrink-0 justify-around [gap:var(--gap)] ${
              reverse ? "animate-marquee-reverse" : "animate-marquee"
            } ${
              pauseOnHover ? "group-hover:[animation-play-state:paused]" : ""
            }`}
          >
            {reviews.map((review, index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </div>
        ))}
    </div>
  );
};

const ReviewCard = ({ profileImg, toolImg, name, username, comment }) => {
  return (
    <figure className="relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4 bg-gray-900 text-white">
      <div className="flex flex-row items-center gap-2 mb-4">
        <img
          className="rounded-full"
          width="40"
          height="40"
          alt=""
          src={profileImg}
        />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium">{name}</figcaption>
          <p className="text-xs font-medium text-gray-400">{username}</p>
        </div>
      </div>
      <div className="flex flex-row items-center gap-4">
        <img className="w-12 h-12 rounded-lg" src={toolImg} alt="AI Tool" />
        <blockquote className="text-sm">{comment}</blockquote>
      </div>
    </figure>
  );
};

export default Marquee;
