import React, { useEffect, useState } from "react";

export const Meteors = ({ number = 30 }) => {
  const [meteorStyles, setMeteorStyles] = useState([]);

  useEffect(() => {
    const styles = [...new Array(number)].map(() => ({
      top: Math.floor(Math.random() * window.innerHeight) + "px", // Random vertical positions
      left: Math.floor(Math.random() * window.innerWidth) + "px", // Random horizontal positions
      animationDelay: Math.random() * 2 + "s", // Random delay for each meteor
      animationDuration: Math.floor(Math.random() * 5 + 2) + "s", // Random duration
    }));
    setMeteorStyles(styles);
  }, [number]);

  return (
    <>
      {[...meteorStyles].map((style, idx) => (
        // Meteor Head
        <span
          key={idx}
          className="pointer-events-none absolute size-0.5 rotate-[215deg] animate-meteor rounded-full bg-slate-500 shadow-[0_0_0_1px_#ffffff10]"
          style={style}
        >
          {/* Meteor Tail */}
          <div className="pointer-events-none absolute top-1/2 -z-10 h-px w-[50px] -translate-y-1/2 bg-gradient-to-r from-slate-500 to-transparent" />
        </span>
      ))}
    </>
  );
};
