import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckOutForm";

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(
  "pk_live_51Q0dZ9GKxes4GcAgbU66Eb6F16o5Qw0v0TaT1x8z5pfdiIrxhRTmYEnFJPUFuC2YEiPGT3Ld5Ez5afIkivuKzy0V002CGXzNn1"
);

export default function ToolMakerWelcome() {
  const [videoFile, setVideoFile] = useState(null);
  const [tagLine, setTagLine] = useState("");
  const [aiTool, setAiTool] = useState("");
  const [description, setDescription] = useState("");
  const [caption, setCaption] = useState("");
  const [uploading, setUploading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeLoaded, setStripeLoaded] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const initializeStripe = async () => {
      // Ensure Stripe.js is loaded
      await stripePromise;
      console.log("Stripe.js has been successfully loaded.");
      setStripeLoaded(true);

      // Create the ment intent and get clientSecret
      const handlepayment = async () => {
        console.log("Initiating ment...");
        try {
          const response = await fetch(
            "https://themindlr.com/api/create-payment-intent",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ amount: 1000 }), // For example, $10.00 USD
            }
          );

          const data = await response.json();
          console.log(
            "payment Intent created. Client secret:",
            data.clientSecret
          );
          setClientSecret(data.clientSecret); // Set the client secret for the ment intent
        } catch (error) {
          console.error("Error creating ment intent:", error);
        }
      };

      handlepayment();
    };

    initializeStripe();
  }, []);

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!videoFile || !name || !email || !password) {
      alert("Please fill in all fields and select a video.");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("video", videoFile);
    formData.append("tagLine", tagLine);
    formData.append("aiTool", aiTool);
    formData.append("description", description);
    formData.append("caption", caption);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await fetch(
        "https://themindlr.com/api/uploadToolCreatorVideo",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        alert("Video uploaded successfully!");
        setVideoFile(null);
        setTagLine("");
        setAiTool("");
        setDescription("");
        setCaption("");
        setName("");
        setEmail("");
        setPassword("");
      } else {
        alert("Failed to upload video.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("An error occurred while uploading the video.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-900 py-10 px-4">
      <h2 className="text-4xl font-poppins text-white mb-10">
        Welcome Tool Maker!
      </h2>

      <div className="w-full max-w-6xl flex flex-wrap md:flex-nowrap space-x-0 md:space-x-8 space-y-8 md:space-y-0">
        {/* Video Upload Section */}
        <div className="bg-slate-700 p-6 rounded-lg shadow-lg w-full md:w-2/3 space-y-6">
          <h3 className="text-3xl text-white font-poppins text-center">
            Upload Video
          </h3>

          <div className="space-y-4">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              className="w-full p-3 rounded-md border border-gray-300"
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="w-full p-3 rounded-md border border-gray-300"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full p-3 rounded-md border border-gray-300"
            />
            <input
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              className="w-full p-3 text-gray-700 rounded-md border border-gray-300"
            />
            <input
              type="text"
              value={tagLine}
              onChange={(e) => setTagLine(e.target.value)}
              placeholder="Tag Line"
              className="w-full p-3 rounded-md border border-gray-300"
            />
            <input
              type="text"
              value={aiTool}
              onChange={(e) => setAiTool(e.target.value)}
              placeholder="AI Tool"
              className="w-full p-3 rounded-md border border-gray-300"
            />
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description of the Tool"
              className="w-full p-3 rounded-md border border-gray-300"
            />
            <textarea
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              placeholder="Caption"
              className="w-full p-3 rounded-md border border-gray-300"
            />
          </div>

          <div className="flex justify-end mt-4">
            <button
              onClick={handleUpload}
              className={`bg-green-600 font-poppins text-white px-6 py-3 rounded-full ${
                uploading ? "opacity-50" : ""
              }`}
              disabled={uploading}
            >
              {uploading ? "Uploading..." : "Post"}
            </button>
          </div>
        </div>

        {/* ment Section */}
        <div className="bg-slate-700 p-8 rounded-lg shadow-lg w-full md:w-1/3 space-y-6">
          <h3 className="text-3xl text-white font-poppins text-center">
            Payment Details
          </h3>
          <div className="flex justify-start space-x-8 mb-8">
            <img src="/images/visalogo.png" alt="Visa" className="h-12 w-24" />
            <img
              src="/images/mastercard1.webp"
              alt="Mastercard"
              className="h-12 w-24"
            />
          </div>
          {clientSecret && stripeLoaded ? (
            <div className="mt-8">
              <Elements stripe={stripePromise}>
                <CheckoutForm clientSecret={clientSecret} />
              </Elements>
            </div>
          ) : (
            <p className="text-white text-center mt-8">
              Loading ment form from Stripe...
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
