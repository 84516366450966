import React from "react";
import { useSwipeable } from "react-swipeable";

export const MiniProfileCard = ({
  user,
  users,
  currentProfile,
  handleProfileDotClick,
  handleNextProfile,
  handlePrevProfile,
}) => {
  // Swipe handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNextProfile(),
    onSwipedRight: () => handlePrevProfile(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div
      {...swipeHandlers}
      className="bg-gray-700 p-4 md:p-6 rounded-lg text-gray-300 font-poppins border border-gray-600 w-full flex flex-col items-center md:max-w-full"
    >
      {/* Container for profile picture */}
      <div
        className="relative bg-gray-600 shadow-lg w-full md:w-full flex items-start md:items-center rounded-l-full rounded-tr-3xl"
        style={{
          borderTopRightRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <div className="rounded-full overflow-hidden h-12 w-12 md:h-24 md:w-24 flex justify-center items-center bg-slate-500">
          <img
            src={user?.profilePic || "/images/finalplaceholder.png"}
            alt="Profile"
            className="h-full w-full object-cover"
          />
        </div>
      </div>

      {/* Name and Bio Section */}
      <div className="mt-2 flex items-start justify-start p-4 rounded-lg border border-gray-600 max-w-max self-start">
        <div>
          <h2 className="text-lg md:text-2xl font-poppins text-gray-200">
            {user?.name || "Loading..."}
          </h2>
          <p className="text-gray-400 font-poppins">
            {user?.bio || "No bio available."}
          </p>
        </div>
      </div>

      {/* Toolkit Section */}
      <div className="mt-4 w-full">
        {user?.toolbox?.length > 0 ? (
          user.toolbox.slice(0, 3).map((toolItem, index) => (
            <div
              key={index}
              className="flex items-start space-x-2 md:space-x-4 w-full py-2"
            >
              {/* Tool Icon and Name */}
              <div className="flex flex-col items-center w-12 md:w-16 flex-shrink-0">
                <img
                  src={toolItem.tool.Image}
                  alt={toolItem.tool.Name}
                  className="w-12 h-12 md:w-16 md:h-16 rounded-full"
                />
                <span className="text-gray-200 text-xs md:text-sm mt-1 text-center">
                  {toolItem.tool.Name}
                </span>
              </div>

              {/* Comment Box inside container */}
              <div className="relative w-full">
                <div className="bg-gray-600 rounded-lg p-3 text-gray-200 border border-gray-500">
                  {toolItem.comment || `Why ${toolItem.tool.Name}?`}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-400">No tools found</p>
        )}
      </div>

      {/* Pagination Dots */}
      <div className="flex justify-center mt-4 space-x-2">
        {users.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 md:w-3 md:h-3 rounded-full cursor-pointer ${
              index === currentProfile ? "bg-gray-400" : "bg-gray-600"
            }`}
            onClick={() => handleProfileDotClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};
