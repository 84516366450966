import React, { useRef, useState } from "react";
import ParticlesAnimation from "../components/ParticlesAnimation";
import ContactForm from "../components/ContactForm";
import ShimmerButton from "../components/ShimmerButton";
import { FaTimes, FaRegPlayCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; // Import useHistory for navigation
import { GoArrowRight } from "react-icons/go";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";
import GradualSpacing from "../components/gradual-spacing";
import { Meteors } from "../components/Meteors"; // Adjust to correct path
import FlickeringGrid from "../components/FlickeringGrid";
import { HyperText } from "../components/HyperText";
import WordPullUp from "../components/WordPullUp";
import { MagicCard } from "../components/MagicCardProp";
import BoxReveal from "../components/BoxReveal";
import { cn } from "../lib/utils"; // Adjust based on relative path to utils.js
import Ripple from "../components/Ripple";

export default function Home() {
  const contactFormRef = useRef(null);
  const videoRef = useRef(null);
  const [isVideoExpanded, setIsVideoExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // Initialize useHistory

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const toggleVideoExpansion = (e) => {
    e.stopPropagation();
    setIsVideoExpanded(!isVideoExpanded);
    if (videoRef.current) {
      if (!isVideoExpanded) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/sign-in");
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden bg-black">
      {/* Hero Section */}
      <div className="relative flex items-center justify-center min-h-screen bg-black">
        {/* FlickeringGrid Background Effect */}
        <FlickeringGrid
          className="absolute inset-0 z-0 w-full h-full"
          squareSize={4}
          gridGap={6}
          color="#6B7280"
          maxOpacity={0.5}
          flickerChance={0.1}
          height={window.innerHeight}
          width={window.innerWidth}
        />

        <div className="absolute top-1/4 text-center z-10 w-full px-4">
          <p className="text-gray-100 text-xl md:text-4xl font-orbitron font-bold">
            <span className="block md:inline">DISCOVER AI TOOLS</span>{" "}
            <span className="block md:inline mt-2">TO AUTOMATE YOUR TASKS</span>
          </p>
        </div>

        {/* Search Bar */}
        <div className="relative z-10 w-full mt-10 md:mt-0 max-w-6xl px-8">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Find an AI solution for your task..."
            className=" font-orbitron w-4/5 py-4 px-4 text-white bg-gray-800 bg-opacity-75 rounded-full text-lg md:text-2xl mx-auto block border border-gray-600 focus:outline-none focus:ring-4 focus:ring-white"
          />
        </div>
      </div>
      {/* "We believe" Section */}
      <div className="w-full text-center">
        {/* Word Pull Up Animation for Heading */}
        <WordPullUp
          className="text-white text-5xl md:text-6xl font-poppins font-semibold tracking-wide w-full leading-tight"
          words="EVERY CHALLENGE HAS AN AI SOLUTION"
        />
        {/* Word Pull Up Animation for Subheading */}
        <WordPullUp
          className="text-gray-300 mt-4 text-2xl md:text-3xl font-poppins font-bold tracking-wider w-full"
          words="Our mission is to help you find it."
        />
      </div>

      {/* Video Container */}
      <div className="w-full flex justify-center mt-8">
        <div
          className={`${
            isVideoExpanded
              ? "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
              : "h-[28rem] w-full max-w-2xl"
          } relative bg-transparent`}
          onClick={!isVideoExpanded ? toggleVideoExpansion : undefined}
        >
          {!isVideoExpanded && (
            <>
              <img
                src="/images/thumbnail.png"
                alt="Video Thumbnail"
                className="object-cover cursor-pointer w-full h-full rounded-lg"
                onClick={toggleVideoExpansion}
              />
              <FaRegPlayCircle
                className="text-white text-8xl absolute inset-0 m-auto cursor-pointer"
                onClick={toggleVideoExpansion}
              />
            </>
          )}
          {isVideoExpanded && (
            <>
              <video
                ref={videoRef}
                className="fixed inset-0 w-auto h-auto max-w-full max-h-full z-50 m-auto rounded-lg"
                controls
                src="/images/new_website_vid.mp4"
                autoPlay
              />
              <button
                className="absolute top-[-6rem] right-0 text-lg bg-black text-white p-2 rounded-full z-50 md:top-[-22rem] md:right-4"
                onClick={toggleVideoExpansion}
              >
                <FaTimes />
              </button>
            </>
          )}
        </div>
      </div>
      {/* Why Mindlr Section */}
      <div className="w-full px-8 md:px-16 lg:px-32 mt-16 bg-black">
        <h2 className="text-6xl font-orbitron text-white mb-6 px-6 text-center">
          Why Mindlr?
        </h2>

        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-8">
          {/* BoxReveal for Content 1 */}
          <BoxReveal boxColor={"#1E3A8A"} duration={0.5} width="100%">
            <div className="w-full p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <RiNumber1 className="text-7xl text-blue-500 font-bold font-poppins mr-4" />
                <h3 className="text-2xl font-poppins text-white">
                  Personalized AI Tool Recommendations
                </h3>
              </div>
              <p className="text-xl font-poppins text-gray-300">
                Get AI tool suggestions tailored to your specific needs and
                workflow.
              </p>
            </div>
          </BoxReveal>

          {/* BoxReveal for Content 2 */}
          <BoxReveal boxColor={"#6D28D9"} duration={0.5} width="100%">
            <div className="w-full p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <RiNumber2 className="text-7xl text-purple-500 font-bold font-poppins mr-4" />
                <h3 className="text-2xl font-poppins text-white">
                  Advanced Video Search and Retrieval
                </h3>
              </div>
              <p className="text-xl text-gray-300 font-poppins">
                Easily find the content you need with our advanced search
                algorithm.
              </p>
            </div>
          </BoxReveal>

          {/* BoxReveal for Content 3 */}
          <BoxReveal boxColor={"#059669"} duration={0.5} width="100%">
            <div className="w-full p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <RiNumber3 className="text-7xl text-green-500 font-bold font-poppins mr-4" />
                <h3 className="text-2xl font-poppins text-white">
                  User-Generated Insights
                </h3>
              </div>
              <p className="text-xl text-gray-300 font-poppins">
                Learn from real users who share their insights and experiences
                with AI tools.
              </p>
            </div>
          </BoxReveal>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="relative w-full max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-center mt-80 mb-60">
        <div className="relative w-full md:w-1/3 h-64 md:h-96 flex items-center justify-center overflow-hidden mb-8 md:mb-0">
          <div
            className="absolute inset-0 border-4 border-white flex flex-col items-center justify-center"
            style={{
              borderRadius: "50%",
              clipPath: "inset(0 0 0 10%)", // Clip the left part of the left circle
            }}
          >
            <h3 className="text-white text-2xl md:text-4xl font-poppins mb-2">
              AI repositories
            </h3>
            <p className="text-white font-poppins text-center text-lg md:text-xl">
              Scroll through thousands of
            </p>
            <p className="text-white font-poppins text-center text-lg md:text-xl">
              tools for your task
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/3 text-center p-4 md:p-8 flex flex-col items-center">
          <h2 className="text-3xl md:text-5xl font-poppins font-bold text-white mb-4">
            MINDLR
          </h2>
          <p className="text-white font-poppins text-base md:text-2xl mb-4">
            Describe your task or situation in layman terms and get user-made
            video recommendations within seconds.
          </p>
          <div className="mt-4">
            <ShimmerButton onClick={scrollToContactForm}>
              Join beta!
            </ShimmerButton>
          </div>
        </div>

        <div className="relative w-full md:w-1/3 h-64 md:h-96 flex items-center justify-center overflow-hidden">
          <div
            className="absolute inset-0 border-4 border-white flex flex-col items-center justify-center"
            style={{
              borderRadius: "50%",
              clipPath: "inset(0 10% 0 0)", // Clip the right part of the right circle
            }}
          >
            <h3 className="text-white text-2xl md:text-4xl font-poppins mb-2">
              AI chatbots
            </h3>
            <p className="text-white font-poppins text-center text-lg md:text-xl">
              Get inaccurate, half-baked
            </p>
            <p className="text-white font-poppins text-center text-lg md:text-xl">
              textual results
            </p>
            <p className="text-white text-center font-poppins text-sm md:text-xl">
              you cannot rely on
            </p>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div
        className="w-full max-w-7xl px-8 md:px-16 lg:px-32 mt-80 mx-auto"
        ref={contactFormRef}
      >
        <ContactForm />
      </div>
    </div>
  );
}
