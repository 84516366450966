import React, { useRef, useState } from "react";
import ParticlesAnimation from "../components/ParticlesAnimation";
import ContactForm from "../components/ContactForm";
import ShimmerButton from "../components/ShimmerButton";
import { FaTimes, FaRegPlayCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; // Import useHistory for navigation
import { GoArrowRight } from "react-icons/go";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";
import GradualSpacing from "../components/gradual-spacing";
import { Meteors } from "../components/Meteors"; // Adjust to correct path
import FlickeringGrid from "../components/FlickeringGrid";
import { HyperText } from "../components/HyperText";
import WordPullUp from "../components/WordPullUp";
import { MagicCard } from "../components/MagicCardProp";
import BoxReveal from "../components/BoxReveal";
import { cn } from "../lib/utils"; // Adjust based on relative path to utils.js
import Ripple from "../components/Ripple";
import { MarqueeDemo } from "../components/MarqueeDemo";
import { MiniProfileCard } from "../components/MiniProfileCard";
import { useSwipeable } from "react-swipeable";
import { FaSearch } from "react-icons/fa";

export default function LandingPageVersion2() {
  const contactFormRef = useRef(null);
  const videoRef = useRef(null);
  const [isVideoExpanded, setIsVideoExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // Initialize useHistory
  const [activeTask, setActiveTask] = useState(0); // To track which task is active
  const [currentProfile, setCurrentProfile] = useState(0);

  const handleProfileDotClick = (index) => {
    setCurrentProfile(index);
  };

  const tasks = [
    {
      title:
        "We need to hire bunch of technical roles and we do not have the team - I need a solution for this",
      steps: [
        {
          tool: { name: "Lever", icon: "/images/Lever.png" },
          description: "For applicant tracking system use Lever.",
        },
        {
          tool: { name: "Calendy", icon: "/images/Calendy Logo.png" },
          description:
            "Integrate calendy and automatically schedule interviews.",
        },
        {
          tool: { name: "HireVue", icon: "/images/Hirevue.png" },
          description:
            "Use Hirevue to conduct and analyze videos of interviews.",
        },
        {
          tool: { name: "Codility", icon: "/images/Codility.jpg" },
          description:
            "Implement Codility for technical skill assessment and analyzed feedback.",
        },
      ],
    },

    {
      title:
        "We have a growing E-commerce company and we are struggling to handle increasing customer requirements",
      steps: [
        {
          tool: {
            name: "DialogueFlow",
            icon: "/images/dialogflow.png",
          },
          description:
            "Implement Dialogue Flow to understand the converstaion naturally and handle requests.",
        },
        {
          tool: { name: "IBMWatson", icon: "/images/IBMWatson.webp" },
          description: "Analyze customer feedback and prioritize user issues.",
        },
        {
          tool: { name: "Algolia", icon: "/images/Algolia.png" },
          description:
            "Integrate Algolia, a knowledge base AI-powered search to improve self service options.",
        },
        {
          tool: { name: "ZenDesk", icon: "/images/ZenDesk.webp" },
          description:
            "Categorize and route tickets to the appropriate departments.",
        },
      ],
    },
  ];

  const users = [
    {
      name: "Charlotte Lee",
      bio: "Advertisement Creator",
      profilePic: "/images/Charlotte Lee.jpg",
      toolbox: [
        {
          tool: { Name: "AdCreative.ai", Image: "/images/AdCreative.png" },
          comment: "I use AdCreative.ai to generate headlines and more",
        },
        {
          tool: { Name: "FeedHive", Image: "/images/FeedHive.png" },
          comment:
            "With FeedHive, I can manage and schedule content across multiple social platforms.",
        },
        {
          tool: { Name: "Superside", Image: "/images/Superside.png" },
          comment:
            "From 3D images to video ads, it helps me produce professional-grade content efficiently",
        },
      ],
    },

    {
      name: "Megan Collins",
      bio: "Interior Designer",
      profilePic: "/images/Megan Collins.jpg",
      toolbox: [
        {
          tool: { Name: "Foyr Neo", Image: "/images/Foyr Neo.jpeg" },
          comment:
            "With Foyr Neo, I can create high-quality 3D renderings and floor plans quickly",
        },
        {
          tool: { Name: "Planner5D", Image: "/images/Planner 5D.png" },
          comment:
            "Using Planner 5D, I can create realistic 2D and 3D visualizations of interior spaces.",
        },
        {
          tool: { Name: "InteriorAI", Image: "/images/InteriorAI.svg" },
          comment: "The AI suggests furniture layouts for me.",
        },
      ],
    },
    {
      name: "Charles Baker",
      bio: "Product Manager",
      profilePic: "/images/Charles Baker.jpg",
      toolbox: [
        {
          tool: { Name: "Amplitude", Image: "/images/Amplitude (1).png" },
          comment:
            "Amplitude's AI tools help me predict user behavior and optimize our product features",
        },
        {
          tool: { Name: "Zedia.io", Image: "/images/Zeda io.jpg" },
          comment:
            " I rely on Zeda.io to prioritize features based on customer feedback.",
        },
        {
          tool: { Name: "AI Mailer", Image: "/images/AI Mailer.svg" },
          comment:
            " This AI-powered tool generates a high-quality emails for me.",
        },
      ],
    },
    {
      name: "Edward Thompson",
      bio: "Stock Trader",
      profilePic: "/images/Edward Thompson.jpg",
      toolbox: [
        {
          tool: { Name: "TradeIdeas", Image: "/images/Trade Ideas.jpeg" },
          comment:
            "I use Trade Ideas to scan market data and backtest strategies.",
        },
        {
          tool: { Name: "TrendSpider", Image: "/images/TrendSpider.png" },
          comment:
            "It has AI-driven chart analysis and automated trendline detection",
        },
        {
          tool: { Name: "MetaStock", Image: "/images/MetaStock.png" },
          comment:
            "I rely on MetaStock's backtesting and forecasting tools to evaluate my strategies",
        },
      ],
    },
  ];

  const handleNextProfile = () => {
    setCurrentProfile((prev) => (prev + 1) % users.length);
  };

  const handlePrevProfile = () => {
    setCurrentProfile((prev) => (prev === 0 ? users.length - 1 : prev - 1));
  };

  const handleDotClick = (index) => {
    setActiveTask(index); // Change active task when clicking dots
  };

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const toggleVideoExpansion = (e) => {
    e.stopPropagation();
    setIsVideoExpanded(!isVideoExpanded);
    if (videoRef.current) {
      if (!isVideoExpanded) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/sign-in");
    }
  };
  const handleNextTask = () => {
    setActiveTask((prevTask) => (prevTask + 1) % tasks.length);
  };

  const handlePrevTask = () => {
    setActiveTask((prevTask) =>
      prevTask === 0 ? tasks.length - 1 : prevTask - 1
    );
  };

  const swipeHandlersForBlueprint = useSwipeable({
    onSwipedLeft: () => handleNextTask(),
    onSwipedRight: () => handlePrevTask(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Enable mouse swipe support for testing on desktop
  });

  return (
    <div className="relative min-h-screen overflow-hidden bg-gray-900">
      {/* Hero Section */}
      <div className="flex flex-col min-h-screen bg-gray-900 ml-6 mr-6 mt-1">
        {/* Order adjusted for mobile: text -> blueprint card -> search bar -> profiles */}
        <div className="flex flex-col md:flex-row w-full mt-0">
          {/* Left Side (Text with BoxReveal effect) */}
          <div className="md:w-2/5 w-full text-white p-0 text-center">
            <p className="text-2xl md:text-4xl font-ubuntu mt-0 md:mt-20 whitespace-normal sm:whitespace-nowrap">
              Discover. Learn. Integrate.
            </p>
            <p className="font-ubuntu mt-4 text-xl md:text-3xl px-4 sm:px-0">
              Find the right tools and blueprints to integrate AI into your
              workflow.
            </p>
          </div>

          <div></div>
          {/* Right Side */}
          <div
            {...swipeHandlersForBlueprint} // Attach the swipe handlers to this div
            className="md:w-3/5 w-full p-2 md:p-4 mt-6 md:mt-0"
          >
            <div className="bg-gray-700 p-4 rounded-lg text-gray-300 font-poppins border border-gray-600">
              {/* Title inside a search bar style */}
              <div className="flex items-center bg-gray-600 text-gray-200 py-3 px-4 rounded-full mb-4 border border-gray-500 shadow-inner w-full justify-between">
                <h2 className="text-sm font-poppins truncate text-center flex-grow">
                  {tasks[activeTask].title}
                </h2>
                <FaSearch className="ml-2 text-gray-400 text-lg" />
              </div>

              {/* Display steps */}
              {tasks[activeTask].steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-center relative mb-8 md:mb-12" // Increase margin-bottom for desktop view
                >
                  {/* Tool Icon and Name */}
                  <div className="w-12 h-12 rounded-full bg-gray-700 text-white flex flex-col items-center relative z-10">
                    <img
                      src={step.tool.icon} // Display the tool's unique icon
                      alt={step.tool.name}
                      className="w-full h-full object-contain rounded-full"
                    />
                    <span className="text-xs text-gray-200 mt-1 font-poppins">
                      {" "}
                      {/* Softer gray for better readability */}
                      {step.tool.name}
                    </span>
                  </div>

                  {/* Step Description */}
                  <div className="ml-4 flex-1 bg-gray-600 p-3 rounded-lg border border-gray-500">
                    <p className="text-sm md:text-normal text-white">
                      {step.description}
                    </p>{" "}
                    {/* Text color adjusted */}
                  </div>

                  {/* Vertical line connecting tools */}
                  {index < tasks[activeTask].steps.length - 1 && (
                    <div
                      className="absolute left-6 border-l-2 border-gray-500 z-0" // Softer gray line color
                      style={{
                        top: "calc(100% + 20px)", // Adjusted for spacing from the tool name
                        height: "40px", // Adjust height as needed for spacing
                      }}
                    ></div>
                  )}
                </div>
              ))}

              {/* Navigation Dots */}
              <div className="flex justify-center mt-4 space-x-3">
                {tasks.map((_, index) => (
                  <span
                    key={index}
                    className={`w-2 h-2 rounded-full cursor-pointer ${
                      activeTask === index ? "bg-gray-400" : "bg-gray-600"
                    }`}
                    onClick={() => setActiveTask(index)} // Set active task on dot click
                  ></span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Search Bar */}
        <div className="w-auto flex justify-center mt-8 md:mt-4">
          <div className="relative w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate("/sign-in");
                }
              }}
              placeholder="Describe your challenge"
              className=" text-center font-poppins w-full py-3 pr-12 pl-4 text-white bg-gray-800 rounded-full text-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <FaSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
          </div>
        </div>

        <div className="flex justify-center mt-16">
          <div
            className="w-3/4 border-t border-white"
            style={{ opacity: 0.3 }}
          ></div>
        </div>

        {/* Gradual Spacing Text */}
        <div className="w-full flex justify-center mt-8 md:mt-16 px-4 md:px-0">
          <div className="text-center md:hidden">
            {/* Visible only on mobile */}
            <div className="text-2xl font-ubuntu text-white tracking-wide">
              Explore the tools used by
            </div>
            <div className="text-2xl font-poppins text-white tracking-wide">
              Industry professionals
            </div>
            <div className="text-2xl font-poppins text-white tracking-wide">
              and your peers
            </div>
          </div>

          <div className="hidden md:block">
            {/* Visible only on desktop */}
            <div className="text-2xl md:text-6xl lg:text-3xl font-ubuntu text-white tracking-wide">
              Explore the tools used by Industry professionals and your peers
            </div>
          </div>
        </div>

        {/* Profile Cards */}
        <div className="w-full flex justify-center p-2 md:p-0 items-center mb-4 mt-4 md:mt-12">
          <div className="w-full md:w-3/5">
            <MiniProfileCard
              user={users[currentProfile]}
              users={users}
              currentProfile={currentProfile}
              handleProfileDotClick={handleProfileDotClick}
              handleNextProfile={handleNextProfile} // Pass swipe next
              handlePrevProfile={handlePrevProfile} // Pass swipe previous
            />
          </div>
        </div>
      </div>

      {/* Why Mindlr Section */}
      <div className="w-full px-8 md:px-16 lg:px-32 mt-24 bg-gray-900">
        <h2 className="text-5xl font-ubuntu text-white mb-6 px-6 text-center">
          Why Mindlr?
        </h2>

        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-8">
          {/* Content 1 */}
          <div className="w-full p-4">
            <div className="flex items-center mb-4">
              <RiNumber1 className="text-7xl text-blue-500 font-bold font-poppins mr-4" />
              <h3 className="text-2xl font-ubuntu text-white">
                Personalized AI Tool Recommendations
              </h3>
            </div>
            <p className="text-xl font-ubuntu text-gray-300">
              Get personalized blueprints to integrate AI into your current
              workflow
            </p>
          </div>

          {/* Content 2 */}
          <div className="w-full p-4">
            <div className="flex items-center mb-4">
              <RiNumber2 className="text-7xl text-purple-500 font-bold font-poppins mr-4" />
              <h3 className="text-2xl font-ubuntu text-white">
                Advanced Video Search and Retrieval
              </h3>
            </div>
            <p className="text-xl text-gray-300 font-ubuntu">
              Advanced video search and retrieval to help you understand how
              each tool works and the use cases
            </p>
          </div>

          {/* Content 3 */}
          <div className="w-full p-4">
            <div className="flex items-center mb-4">
              <RiNumber3 className="text-6xl text-green-500 font-bold font-poppins mr-4" />
              <h3 className="text-2xl font-ubuntu text-white">
                User-Generated Insights
              </h3>
            </div>
            <p className="text-xl text-gray-300 font-ubuntu">
              User-generated insights and a personalized dashboard to showcase
              your tools
            </p>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div
        className="w-full max-w-7xl px-8 md:px-16 lg:px-32 mt-20 mx-auto"
        ref={contactFormRef}
      >
        <ContactForm />
      </div>
    </div>
  );
}
