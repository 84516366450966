import React, { useState } from "react";
import ShimmerButton from "./ShimmerButton";
import { NeonGradientCard } from "./NeonGradientCard"; // Adjust the path as necessary

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { name, email };
    setSubmitted(true);
    try {
      const response = await fetch("https://themindlr.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        //setSubmitted(true);
        setName("");
        setEmail("");
      } else {
        alert("Failed to submit the form");
      }
    } catch (error) {
      console.error("Error submitting the form", error);
      alert("An error occurred while submitting the form");
    }
  };

  return (
    <div
      id="contact-form"
      className="flex justify-center items-center mt-12 mb-12 px-4"
    >
      {!submitted ? (
        <NeonGradientCard className="w-full max-w-md p-0 bg-black">
          <form onSubmit={handleSubmit} className="px-6 py-8">
            <h2 className="text-4xl font-poppins mb-4 text-center text-black dark:text-white">
              Join the Waitlist!
            </h2>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Name *
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-white text-black dark:bg-black dark:text-white"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block mb-2 font-poppins text-black dark:text-white"
              >
                Email *
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-white text-black dark:bg-black dark:text-white"
                required
              />
            </div>
            <div className="flex justify-center">
              <ShimmerButton>Submit</ShimmerButton>
            </div>
          </form>
        </NeonGradientCard>
      ) : (
        <div className=" font-ubuntu text-center text-4xl text-white dark:text-white  p-4 rounded-md">
          Thank you! Kindly check your email.
        </div>
      )}
    </div>
  );
}
