import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import VideoCard from "../components/VideoCard";
import NotificationPopup from "../components/NotificationPopup";
import FeedbackForm from "../components/FeedbackForm";

const UploaderProfile = () => {
  const { uploaderId } = useParams();
  const [uploader, setUploader] = useState(null);
  const [activeTab, setActiveTab] = useState("posts");
  const [videos, setVideos] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);
  const [userId, setUserId] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [activeSection, setActiveSection] = useState(null); // Tracks followers/following dropdown
  const [userList, setUserList] = useState([]); // Followers/Following list
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch("https://themindlr.com/api/user", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setUserId(userData._id);
          checkIfFollowing(userData._id);
        } else {
          console.error("Failed to fetch user ID");
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    };

    const fetchUploaderInfo = async () => {
      try {
        const response = await fetch(
          `https://themindlr.com/api/uploader/${uploaderId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const uploaderData = await response.json();
          setUploader(uploaderData);
          fetchUploaderVideos(uploaderId);
        } else {
          console.error("Failed to fetch uploader info");
        }
      } catch (error) {
        console.error("Error fetching uploader info:", error);
      }
    };

    const fetchUploaderVideos = async (uploaderId) => {
      try {
        const response = await fetch(
          `https://themindlr.com/api/uploader-videos/${uploaderId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const videoData = await response.json();
          setVideos(videoData);
        } else {
          console.error("Failed to fetch videos:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const checkIfFollowing = async (loggedInUserId) => {
      try {
        const response = await fetch(
          `https://themindlr.com/api/check-follow-status/${uploaderId}?userId=${loggedInUserId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          setIsFollowing(data.isFollowing); // Set the follow state
        } else {
          console.error("Failed to fetch follow status");
        }
      } catch (error) {
        console.error("Error checking follow status:", error);
      }
    };

    fetchUserId();
    fetchUploaderInfo();
  }, [uploaderId]);

  const handleFollow = async () => {
    try {
      const response = await fetch(
        `https://themindlr.com/api/follow/${uploaderId}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );
      if (response.ok) {
        setIsFollowing(true);
        setUploader((prev) => ({
          ...prev,
          followers: [...prev.followers, userId],
        }));
      } else {
        console.error("Failed to follow user:", response.statusText);
      }
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const handleNotificationClick = async () => {
    try {
      const response = await fetch(
        "https://themindlr.com/api/notifications/mark-read",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        setNotificationCount(0); // Reset notification count to 0
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }

    setShowNotificationPopup((prev) => !prev); // Toggle the notification popup
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch("https://themindlr.com/api/logout", {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        navigate("/sign-in");
      } else {
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch("https://themindlr.com/api/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          userId: userId, // Send the user ID in the request body
          currentPassword,
          newPassword,
        }),
      });

      if (response.ok) {
        alert("Password updated successfully");
        setShowResetPasswordPopup(false);
      } else {
        const data = await response.json();
        alert(`Failed to update password: ${data.message}`);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  const toggleSection = (section) => {
    if (activeSection === section) {
      setActiveSection(null); // Close dropdown
      setUserList([]); // Reset the list
    } else {
      setActiveSection(section); // Open the clicked section
      setUserList(
        section === "followers" ? uploader.followers : uploader.following
      );
    }
  };

  const navigateToUploaderProfile = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "posts":
        return (
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-1 mt-8">
              {videos.map((video) => (
                <div id={video._id} key={video._id}>
                  <VideoCard video={video} className="h-36" />
                </div>
              ))}
            </div>
          </div>
        );

      case "namekit":
        return (
          <div className="ml-4 md:ml-6 mb-20 md:mb-0">
            <h2 className="text-lg font-bold mb-4">
              {uploader?.name || "Uploader"}'s Toolkit
            </h2>
            <div className="space-y-8">
              {uploader?.toolbox && uploader.toolbox.length > 0 ? (
                uploader.toolbox.map((toolItem, index) => {
                  if (!toolItem.tool) {
                    console.error(
                      "Tool details are missing for item:",
                      toolItem
                    );
                    return null;
                  }

                  return (
                    <div
                      key={toolItem._id.toString()}
                      className="flex items-center space-x-4 ml-6"
                    >
                      <div className="flex flex-col items-center w-20 md:w-48 space-y-2">
                        <img
                          src={toolItem.tool.Image}
                          alt={toolItem.tool.Name}
                          className="w-12 h-12 mt-6 rounded-full"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images/defaultprofpic1.jpg";
                          }}
                        />
                        <span className="mt-2 text-black">
                          {toolItem.tool.Name}
                        </span>
                      </div>
                      <div
                        className="p-4 text-black bg-gray-300 rounded-lg md:rounded-full w-full"
                        style={{
                          wordBreak: "break-word",
                          lineHeight: "1.5",
                          minHeight: "50px",
                        }}
                      >
                        {toolItem.comment || "No comment provided"}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No tools found in the uploader's toolkit.</p>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="relative min-h-screen flex pt-0 md:pt-0 bg-white">
      <div className="fixed bottom-4 mb-16 right-4 md:bottom-6 md:right-6 z-50">
        <FeedbackForm userId={userId} />
      </div>
      <div className="w-[220px] bg-slate-300 hidden md:flex"></div>
      {/* Main Content */}
      <div className="flex-1 pr-4">
        <div className="bg-white p-4 mt-1">
          {/* Profile Picture Container */}
          <div
            className="bg-gray-300 shadow-lg w-full flex items-start md:items-center rounded-l-full rounded-tr-3xl"
            style={{
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
              position: "relative",
            }}
          >
            {/* Profile Picture */}
            <div className="rounded-full overflow-hidden h-24 w-24 md:h-32 md:w-32 flex justify-center items-center bg-slate-500">
              <img
                src={uploader?.profilePic || "/images/finalplaceholder.png"}
                alt="Profile"
                className="h-full w-full object-cover"
              />
            </div>

            {/* Follow Button */}
            {uploaderId !== userId && (
              <button
                onClick={handleFollow}
                className={`ml-4 md:ml-6 w-32 h-12 mt-6 md:mt-0 md:w-40 md:h-14 text-white font-poppins text-lg ${
                  isFollowing ? "bg-green-500" : "bg-blue-500"
                } flex justify-center items-center`}
                style={{
                  borderRadius: "8px",
                }}
                disabled={isFollowing}
              >
                {isFollowing ? "Following" : "Follow"}
              </button>
            )}
          </div>

          {/* Name and Bio Container */}
          <div
            className="mt-4 flex items-center bg-white"
            style={{ borderBottom: "none" }}
          >
            <div className="flex-1 bg-white shadow-md rounded-lg px-6 py-4 inline-block">
              <h2 className="md:text-2xl font-poppins text-black">
                {uploader?.name || "Loading..."}
              </h2>
              <p className="text-black font-poppins">
                {uploader?.bio || "No bio available."}
              </p>
            </div>
          </div>

          {/* Followers and Following */}
          <div className="mt-4 flex justify-start items-center space-x-4">
            <div
              className="bg-gray-300 shadow-md rounded-lg px-4 py-2 cursor-pointer"
              onClick={() => toggleSection("followers")}
            >
              <p className="text-black text-center">
                <span className="font-bold">
                  {uploader?.followers?.length || 0}
                </span>{" "}
                Followers
              </p>
            </div>
            <div
              className="bg-gray-300 shadow-md rounded-lg px-4 py-2 cursor-pointer"
              onClick={() => toggleSection("following")}
            >
              <p className="text-black text-center">
                <span className="font-bold">
                  {uploader?.following?.length || 0}
                </span>{" "}
                Following
              </p>
            </div>
          </div>

          {/* Followers and Following List */}
          {activeSection && (
            <div className="mt-2 bg-white rounded-lg shadow-md p-4 max-h-60 overflow-y-auto">
              <h2 className="text-lg font-bold text-black mb-4">
                {activeSection === "followers" ? "Followers" : "Following"}
              </h2>
              {userList.length > 0 ? (
                userList.map((uploaderItem) => (
                  <div
                    key={uploaderItem._id}
                    className="flex items-center space-x-4 cursor-pointer mb-2"
                  >
                    <img
                      src={
                        uploaderItem.profilePic ||
                        "/images/finalplaceholder.png"
                      }
                      alt={uploaderItem.name}
                      className="w-12 h-12 rounded-full object-cover cursor-pointer"
                      onClick={() =>
                        navigateToUploaderProfile(uploaderItem._id)
                      }
                    />
                    <span className="text-black">{uploaderItem.name}</span>
                  </div>
                ))
              ) : (
                <p className="text-black font-poppins">No users found.</p>
              )}
            </div>
          )}

          {/* Tabs Section */}
          <div className="mt-4 flex justify-between border-b">
            <button
              className={`flex-1 px-2 py-2 font-poppins md:text-xl text-black text-center rounded-full ${
                activeTab === "namekit" ? "bg-gray-500 text-white" : ""
              }`}
              onClick={() => setActiveTab("namekit")}
            >
              {uploader?.name || "Uploader"}'s Namekit
            </button>
            <button
              className={`flex-1 px-2 py-2 font-poppins md:text-xl text-black text-center rounded-full ${
                activeTab === "posts" ? "bg-gray-500 text-white" : ""
              }`}
              onClick={() => setActiveTab("posts")}
            >
              Posts
            </button>
            <button
              className={`flex-1 px-2 py-2 font-poppins  md:text-xl text-black text-center rounded-full ${
                activeTab === "interactions" ? "bg-gray-500 text-white" : ""
              }`}
              onClick={() => setActiveTab("interactions")}
            >
              Interactions
            </button>
          </div>

          {renderContent()}
        </div>
      </div>

      {/* Sidebar for Desktop (on the right now) */}
      <div className="hidden md:flex flex-col justify-start items-center bg-slate-300 p-4 rounded-lg w-60 h-auto mt-2 mr-4">
        <div className="flex flex-row items-center justify-between w-full relative">
          <Link to="/home">
            <span
              className="material-icons text-black"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              home
            </span>
          </Link>

          <Link to="/profile">
            <span
              className="material-icons text-black"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              person
            </span>
          </Link>

          <div className="relative">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={handleNotificationClick}
            >
              notifications
            </span>
            {notificationCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-4px",
                  right: "60%",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "14px",
                }}
              >
                {notificationCount}
              </span>
            )}
            {showNotificationPopup && (
              <NotificationPopup
                userId={userId}
                onClose={() => setShowNotificationPopup(false)}
                position="down"
              />
            )}
          </div>

          <div className="relative">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={toggleSettings}
            >
              settings
            </span>
            {settingsOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                <button
                  onClick={() => setShowResetPasswordPopup(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => setShowLogoutPopup(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Sticky Header for Mobile View */}
      <div className="fixed bottom-0 left-0 w-screen right-0 bg-slate-200 p-4 flex justify-between items-center md:hidden z-50">
        <Link to="/home" className="flex-1 text-center">
          <span
            className="material-icons text-black"
            style={{
              cursor: "pointer",
              fontSize: "32px",
            }}
          >
            home
          </span>
        </Link>

        <Link to="/profile" className="flex-1 text-center">
          <span
            className="material-icons text-black"
            style={{
              cursor: "pointer",
              fontSize: "32px",
            }}
          >
            person
          </span>
        </Link>

        <div className="relative flex-1 text-center">
          <span
            className="material-icons text-black cursor-pointer"
            style={{ fontSize: "32px" }}
            onClick={handleNotificationClick}
          >
            notifications
          </span>
          {notificationCount > 0 && (
            <span
              style={{
                position: "absolute",
                top: "-4px",
                right: "20%",
                background: "red",
                color: "white",
                borderRadius: "50%",
                padding: "2px 6px",
                fontSize: "14px",
              }}
            >
              {notificationCount}
            </span>
          )}
          {/* Conditionally render the NotificationPopup */}
          {showNotificationPopup && (
            <NotificationPopup
              userId={userId}
              onClose={() => setShowNotificationPopup(false)}
              position="up"
            />
          )}
        </div>

        <div className="relative flex-1 text-center">
          <span
            className="material-icons text-black cursor-pointer"
            style={{ fontSize: "32px" }}
            onClick={toggleSettings}
          >
            settings
          </span>

          {settingsOpen && (
            <div className="absolute right-0 bottom-12 w-48 bg-white rounded-md shadow-lg z-50">
              <button
                onClick={() => setShowResetPasswordPopup(true)}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Reset Password
              </button>
              <button
                onClick={() => setShowLogoutPopup(true)}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Log Out
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold font-poppins text-black mb-4">
              Are you sure?
            </h2>
            <p className="mb-6">Do you want to log out?</p>
            <div className="flex justify-around">
              <button
                onClick={() => setShowLogoutPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                No
              </button>
              <button
                onClick={handleLogout}
                className="bg-red-500 text-black px-4 py-2 rounded-full"
              >
                Yes, Log Out
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reset Password Popup */}
      {showResetPasswordPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold text-black mb-4">
              Reset Password
            </h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Current Password
              </label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-bold mb-2">
                New Password
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="flex justify-around">
              <button
                onClick={() => setShowResetPasswordPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                Cancel
              </button>
              <button
                onClick={handleResetPassword}
                className="bg-blue-500 text-black px-4 py-2 rounded-full"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploaderProfile;
