// MarqueeDemo.js
import React from "react";
import Marquee from "./Marquee";

const reviews = [
  {
    name: "Jack",
    username: "@jack",
    profileImg: "/images/profpic1.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment:
      "RunwayML's background removal and AI-rated effects are perfect for creating unique visuals quickly.",
  },
  {
    name: "Jill",
    username: "@jill",
    profileImg: "/images/profpic2.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment:
      "CapCut’s AI-powered video shortening automatically picks standout moments.",
  },
  {
    name: "John",
    username: "@john",
    profileImg: "/images/profpic3.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment:
      "Auto Reframe automatically adjusts videos for different platforms.",
  },
  {
    name: "Jane",
    username: "@jane",
    profileImg: "/images/profpic4.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment: "I use AI tools to speed up my workflow and ensure consistency.",
  },
  {
    name: "Jack",
    username: "@jack",
    profileImg: "/images/profpic1.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment:
      "RunwayML's background removal and AI-rated effects are perfect for creating unique visuals quickly.",
  },
  {
    name: "Jill",
    username: "@jill",
    profileImg: "/images/profpic2.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment:
      "CapCut’s AI-powered video shortening automatically picks standout moments.",
  },
  {
    name: "John",
    username: "@john",
    profileImg: "/images/profpic3.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment:
      "Auto Reframe automatically adjusts videos for different platforms.",
  },
  {
    name: "Jane",
    username: "@jane",
    profileImg: "/images/profpic4.jpg",
    toolImg: "/images/gptlogo.jpeg",
    comment: "I use AI tools to speed up my workflow and ensure consistency.",
  },
];

export function MarqueeDemo() {
  const firstRow = reviews.slice(0, reviews.length / 2);
  const secondRow = reviews.slice(reviews.length / 2);

  return (
    <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden bg-black rounded-lg border">
      {/* First Row Marquee */}
      <Marquee reviews={firstRow} className="[--duration:20s]" />

      {/* Second Row Marquee with Reverse Animation */}
      <Marquee
        reviews={secondRow}
        reverse={true}
        className="[--duration:20s]"
      />

      {/* Gradient Fading Effect */}
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-black"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-black"></div>
    </div>
  );
}
