import { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa"; // Import the arrow icon

const SidebarPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Add a delay of 1 second before showing the popup
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 1000); // 1 second delay

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);

  const handlePopupDismiss = () => {
    setShowPopup(false); // Hide the popup when OK is clicked
  };

  return (
    <>
      {showPopup && (
        <div
          className="absolute top-96 left-5 z-50 bg-white text-black p-2 rounded-md shadow-lg w-56"
          style={{
            top: "62%", // Adjust this value to bring the popup closer to the + icon
            left: "-6px", // Adjust for horizontal positioning if needed
          }}
        >
          <div className="flex items-center justify-between">
            <span className="text-normal font-medium ml-4">
              Click on the + icon to search and add tools
            </span>
            {/* Use arrow pointing right */}
            <FaArrowRight className="ml-2 text-gray-600 text-3xl" />
          </div>
          <div className="flex justify-center mt-2">
            <button
              onClick={handlePopupDismiss}
              className="bg-gray-800 text-white text-xs px-4 py-1 rounded-md"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SidebarPopup;
