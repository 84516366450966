import React from "react";

const ShimmerButton = React.forwardRef(
  (
    {
      shimmerColor = "rgba(255,255,255,0.2)", // Subtle shimmer effect
      shimmerSize = "0.2em",
      shimmerDuration = "3s",
      borderRadius = "100px",
      background = "#101010", // Deep black background
      textColor = "white", // Text color
      className,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        style={{
          "--spread": "90deg",
          "--shimmer-color": shimmerColor,
          "--radius": borderRadius,
          "--speed": shimmerDuration,
          "--cut": shimmerSize,
          "--bg": background,
          "--text-color": textColor,
        }}
        className={`relative z-0 flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap px-6 py-3 [background:var(--bg)] [border-radius:var(--radius)] text-[var(--text-color)] transition-all duration-300 ease-in-out group hover:bg-gray-800 ${className}`}
        ref={ref}
        {...props}
      >
        {/* Shimmer effect */}

        <span className="relative z-10">{children}</span>
        {/* Text styling */}
        <div className="absolute inset-0 rounded-full px-4 py-1.5 text-sm font-medium shadow-[inset_0_-8px_10px_rgba(255,255,255,0.1)] transition-shadow duration-300 group-hover:shadow-[inset_0_-10px_12px_rgba(255,255,255,0.2)]" />
        <div className="absolute -z-20 [background:var(--bg)] [border-radius:var(--radius)]" />
      </button>
    );
  }
);

ShimmerButton.displayName = "ShimmerButton";

export default ShimmerButton;
