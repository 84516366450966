import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ShimmerButton from "../components/ShimmerButton";
import { NeonGradientCard } from "../components/NeonGradientCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function SignIn() {
  const [identifier, setIdentifier] = useState(""); // Identifier can be email or username
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { identifier, password };

    try {
      const response = await fetch("https://themindlr.com/api/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include",
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.message === "Sign in successful") {
          navigate("/home");
        }
      } else if (response.status === 400) {
        setErrorMessage("Invalid credentials. Please try again.");
      } else if (response.status === 404) {
        setErrorMessage("User does not exist. Please check your credentials.");
      }
    } catch (error) {
      console.error("Error signing in", error);
      setErrorMessage(
        "An error occurred while signing in. Please try again later."
      );
    }
  };

  return (
    <div className="flex justify-center items-center mt-12 mb-12 px-4 relative">
      <NeonGradientCard className="w-full max-w-md p-0 bg-black">
        <form onSubmit={handleSubmit} className="px-6 py-8">
          <h2 className="text-4xl font-poppins mb-4 text-center text-black dark:text-white">
            Sign In
          </h2>
          <div className="mb-4">
            <label
              htmlFor="identifier"
              className="block mb-2 font-poppins text-black dark:text-white"
            >
              Email/Username
            </label>
            <input
              type="text"
              id="identifier"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block mb-2 font-poppins text-black dark:text-white"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-black text-white"
                required
              />
              <span
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={20} />
                ) : (
                  <AiOutlineEye size={20} />
                )}
              </span>
            </div>
          </div>

          {errorMessage && (
            <p className="text-red-500 font-poppins mb-4 text-center">
              {errorMessage}
            </p>
          )}

          <div className="flex justify-center mt-4">
            <ShimmerButton className="font-poppins mx-auto md:mx-0">
              Sign In
            </ShimmerButton>
          </div>
        </form>
      </NeonGradientCard>
      <ToastContainer />
    </div>
  );
}
