import React, { useState, useEffect, useRef } from "react";
import VideoCard from "../components/VideoCard";
import { BsStars } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { useNavigate, Link } from "react-router-dom";
import NotificationPopup from "../components/NotificationPopup";
import Disclaimer from "../components/Disclaimer";
import FeedbackForm from "../components/FeedbackForm";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import WelcomePopup from "../components/WelcomePopup";

export default function WelcomePage() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [user, setUser] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showTestingText, setShowTestingText] = useState(false); // New state
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false); // Initial state to show the disclaimer
  const navigate = useNavigate();
  const profilePicInputRef = useRef(null);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [textualResults, setTextualResults] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState(null);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [showRewritePrompt, setShowRewritePrompt] = useState(false); // Control to show or hide rewritten prompt
  const [threeSuggestions, setThreeSuggestions] = useState([]); // Store the rewritten prompt suggestions
  const [manualSearch, setManualSearch] = useState(false);

  const settingsRef = useRef(null);
  const notificationsRef = useRef(null);
  // Function to handle "Agree" action in the disclaimer

  // Check if disclaimer has already been accepted
  useEffect(() => {
    const isDisclaimerAccepted = localStorage.getItem("disclaimerShown");
    if (isDisclaimerAccepted) {
      setDisclaimerAccepted(true);
    }
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://themindlr.com/api/user", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          fetchNotificationCount(userData._id); // Fetch notifications count
        } else {
          console.error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const checkAuthCookie = () => {
    return document.cookie.includes("token"); // Replace 'authToken' with your cookie name
  };

  useEffect(() => {
    const isAuthenticated = checkAuthCookie();

    if (isAuthenticated && !localStorage.getItem("disclaimerShown")) {
      // Show disclaimer only if the user is authenticated and hasn't seen the disclaimer yet
      setShowDisclaimer(true);
    }
  }, []);

  const handleAgree = () => {
    setShowDisclaimer(false);
    // Set the localStorage flag so disclaimer doesn't show again during this session
    localStorage.setItem("disclaimerShown", "true");
    setDisclaimerAccepted(true);
  };

  const handleDisagree = () => {
    // Handle logic if the user disagrees
  };

  useEffect(() => {
    if (!searchTriggered) {
      const defaultQuery = "ai tool"; // Default query in the background
      handleSearch(defaultQuery); // Trigger search with the default query
      setSearchTriggered(true); // Mark search as triggered
    }
  }, [searchTriggered]);

  // Use another effect for handling the search when the query changes

  const fetchNotificationCount = async (userId) => {
    try {
      const response = await fetch(
        "https://themindlr.com/api/notifications/count",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNotificationCount(data.unreadCount);
      } else {
        console.error("Failed to fetch notification count");
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  const handleNotificationClick = async () => {
    setShowNotificationPopup((prev) => !prev); // Toggle notification popup
    setNotificationsOpen((prev) => !prev);
    try {
      const response = await fetch(
        "https://themindlr.com/api/notifications/mark-read",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: user._id }),
        }
      );

      if (response.ok) {
        setNotificationCount(0);
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleSearch = async (searchQuery = query) => {
    // Use searchQuery for flexibility, default to `query` if not provided
    if (!searchQuery || searchQuery === lastSearchQuery) return; // Prevent empty or repeated searches

    const apiUrl = `https://app.themindlr.com/search?query=${encodeURIComponent(
      searchQuery
    )}`;
    setLoading(true);
    setError(null);
    setSearchSubmitted(true);

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const searchData = await response.json();
      setTextualResults(searchData.textual_results);

      const videoNames = searchData.video_results.map((name) =>
        name.endsWith(".mp4") ? name : `${name}.mp4`
      );

      const videoResponse = await fetch(
        "https://themindlr.com/api/getsearchVideos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ videoNames, query: searchQuery }), // Use the correct query here
        }
      );

      if (!videoResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const videoData = await videoResponse.json();
      setResults(videoData);
    } catch (error) {
      setError("Failed to fetch search results");
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleProfilePicUpload = async (e) => {
    const formData = new FormData();
    formData.append("profilePic", e.target.files[0]);

    try {
      const response = await fetch(
        "https://themindlr.com/api/user/profile-pic",
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        setUser(updatedUser);
        console.log("Updated User:", updatedUser);
      } else {
        console.error("Failed to upload profile picture");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const toggleSettings = () => {
    //setSettingsOpen(!settingsOpen);
    setSettingsOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    localStorage.removeItem("disclaimerShown");
    try {
      const response = await fetch("https://themindlr.com/api/logout", {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        navigate("/sign-in");
      } else {
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch("https://themindlr.com/api/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // This is important to send the cookies with the request
        body: JSON.stringify({
          userId: user._id, // Send the user ID in the request body
          currentPassword,
          newPassword,
        }),
      });

      if (response.ok) {
        alert("Password updated successfully");
        setShowResetPasswordPopup(false);
      } else {
        // Try to parse the response as JSON
        try {
          const data = await response.json();
          alert(`Failed to update password: ${data.message}`);
        } catch (jsonError) {
          // If parsing fails, display a general error message
          alert("Failed to update password: An error occurred.");
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  const handleRewritePromptClick = async () => {
    try {
      const response = await fetch("https://themindlr.com/api/rewrite-query", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }), // Send the current query
      });

      if (response.ok) {
        const data = await response.json();
        const suggestionsArray = data.data.split("\n").filter(Boolean); // Adjust this split logic if necessary
        setThreeSuggestions(suggestionsArray); // Save the rewritten suggestions
        setShowRewritePrompt(true); // Show the suggestions box
      } else {
        console.error("Error fetching query rewrites");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion); // Set the query to the clicked suggestion
    handleSearch(); // Trigger the search with the new suggestion
  };

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const handleVideoPlay = (videoRef) => {
    // Pause the previously playing video
    if (currentlyPlayingVideo && currentlyPlayingVideo !== videoRef) {
      currentlyPlayingVideo.pause();
    }
    // Set the new currently playing video
    setCurrentlyPlayingVideo(videoRef);
  };

  const TypingText = ({ text }) => {
    return <p>{text}</p>; // Just return the entire sentence
  };

  const TextualResultsWithTyping = ({ textualResults }) => {
    const [activeTextIndex, setActiveTextIndex] = useState(0);

    // Split the textual results by sentences (splitting on periods, exclamation marks, or question marks followed by a space)
    const sentences = textualResults.split(/(?<=[.?!])\s+/);

    useEffect(() => {
      if (activeTextIndex < sentences.length) {
        const timer = setTimeout(() => {
          setActiveTextIndex((prev) => prev + 1);
        }, 1000); // Delay between sentences (you can adjust the 1000ms to make it faster/slower)

        return () => clearTimeout(timer); // Cleanup on unmount
      }
    }, [activeTextIndex, sentences]);

    return (
      <div className="textual-results p-4 mb-4 text-black font-poppins bg-gray-100 rounded-md">
        {/* Display each sentence progressively */}
        {sentences.slice(0, activeTextIndex + 1).map((sentence, index) => (
          <TypingText key={index} text={sentence.trim()} />
        ))}
      </div>
    );
  };

  return (
    <div className="bg-gray-200 min-h-screen p-0">
      {/* Top Bar for Desktop View */}
      {/* Top Bar for Desktop View */}
      <div className="hidden md:flex items-center justify-between bg-gray-500 p-4 px-3 rounded-none">
        <div className="flex items-center justify-center w-full md:w-3/4 mx-auto md:ml-64">
          <div className="relative">
            {/* Mindlr Logo */}
            <img
              src="/images/mindrllogo.png"
              alt="Mindlr Logo"
              className="h-11 w-15 mx-2 mt-1 hidden md:block"
              style={{ marginBottom: "0.5rem" }}
            />
            {/* WelcomePopup next to logo */}

            <WelcomePopup />
          </div>

          <div className="relative w-full max-w-3xl h-12">
            <BsStars className="absolute left-4 top-1/2 transform -translate-y-1/2 text-black text-2xl md:text-3xl" />
            <input
              type="text"
              value={manualSearch ? query : ""} // If manual search, show the query, else keep empty
              onChange={(e) => {
                setQuery(e.target.value); // Update the query state on user input
                setManualSearch(true); // Mark as manual search
              }}
              onKeyDown={handleKeyPress}
              placeholder="Describe your task or situation"
              className="h-full pl-16 bg-gray-700 w-full border border-gray-600 rounded-2xl text-white pr-12 text-lg"
            />
            <CiSearch
              onClick={handleSearch}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-black text-3xl md:text-4xl"
            />
          </div>
        </div>

        {/* Icons (Profile, Notifications, Settings) */}
        <div className="flex justify-around w-1/4 space-x-2">
          <Link to="/profile">
            <span className="material-icons text-black text-3xl md:text-5xl">
              person
            </span>
          </Link>
          <div className="relative">
            <span
              className="material-icons text-black text-3xl md:text-5xl cursor-pointer"
              onClick={handleNotificationClick}
              ref={notificationsRef}
            >
              notifications
            </span>
            {notificationCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-4px",
                  right: "20%",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "14px",
                }}
              >
                {notificationCount}
              </span>
            )}
            {showNotificationPopup && (
              <NotificationPopup
                userId={user?._id}
                onClose={() => setShowNotificationPopup(false)}
              />
            )}
          </div>
          <span
            className="material-icons text-black text-3xl md:text-5xl cursor-pointer"
            onClick={toggleSettings}
            ref={settingsRef}
          >
            settings
          </span>
          {settingsOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
              <button
                onClick={() => {
                  setShowResetPasswordPopup(true); // Open reset password popup first
                  setTimeout(() => setSettingsOpen(false), 100); // Close the settings dropdown after a slight delay to allow the popup to open
                }}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Reset Password
              </button>
              <button
                onClick={() => {
                  console.log("log out clicked");
                  setShowLogoutPopup(true); // Open logout popup first
                  setTimeout(() => setSettingsOpen(false), 100); // Close the settings dropdown after a slight delay
                }}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Log Out
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Top Bar for Mobile View */}
      <div className="bg-gray-500 p-3 px-2 rounded-none md:hidden fixed top-0 left-0 w-full z-50">
        <div className="flex items-center justify-start w-full">
          {/* Profile Picture for Mobile View */}
          <img
            src="/images/mindrllogo.png"
            alt="Mindlr Logo"
            className="h-9 w-14 mx-2 mt-2 md:mt-0"
            style={{ marginBottom: "0.5rem" }}
          />
          <div className="relative w-full max-w-3xl h-12 ml-0">
            {" "}
            {/* Adjusted margin to better center the search bar */}
            <BsStars className="absolute left-3 top-1/2 transform -translate-y-1/2 text-black text-2xl md:text-3xl" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="type anything"
              className="h-full pl-14 bg-gray-700 w-full border border-gray-600 rounded-2xl text-white pr-12 text-lg"
            />
            <CiSearch
              onClick={handleSearch}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-black text-3xl md:text-4xl"
            />
          </div>
        </div>
      </div>

      {/* Sticky Footer for Mobile View */}
      <div>
        {/* Conditionally render the Disclaimer component */}
        {!disclaimerAccepted && (
          <Disclaimer onAgree={handleAgree} onDisagree={handleDisagree} />
        )}

        {/* Sticky Footer - only show after the disclaimer is accepted */}
        {disclaimerAccepted && (
          <div className="fixed bottom-0 left-0 w-full bg-slate-200 p-4 flex justify-between items-center md:hidden z-50">
            <Link to="/profile">
              <img
                src={user?.profilePic || "/images/finalplaceholder.png"}
                className="rounded-full items-stretch  w-10 h-10 md:w-28 md:h-28  cursor-pointer"
              />
            </Link>

            <div className="relative">
              <span
                className="material-icons text-black cursor-pointer"
                style={{ fontSize: "32px" }}
                onClick={handleNotificationClick}
              >
                notifications
              </span>
              {notificationCount > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-6px", // Adjust this value to move the notification count up or down
                    right: "-10px", // Adjust this value to move the notification count left or right
                    background: "red",
                    color: "white",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "14px",
                  }}
                >
                  {notificationCount}
                </span>
              )}
              {showNotificationPopup && (
                <NotificationPopup
                  userId={user?._id}
                  onClose={() => setShowNotificationPopup(false)}
                  position="up"
                />
              )}
            </div>

            <div className="relative">
              <span
                className="material-icons text-black cursor-pointer"
                style={{ fontSize: "32px" }}
                onClick={toggleSettings}
              >
                settings
              </span>

              {settingsOpen && (
                <div className="absolute bottom-14 right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                  <button
                    onClick={() => setShowResetPasswordPopup(true)}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Reset Password
                  </button>
                  <button
                    onClick={() => setShowLogoutPopup(true)}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Log Out
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex">
        {/* Left Sidebar for Desktop */}
        <div className="hidden md:flex bg-gray-500 opacity-90 shadow-lg w-1/6 mt-1 mb-1 ml-1 rounded-lg min-h-screen py-4 flex-col items-center justify-start">
          <div className="flex flex-col items-center mt-4">
            <img
              src={user?.profilePic || "/images/finalplaceholder.png"}
              alt="Profile"
              className="rounded-full w-40 h-40 md:w-28 md:h-28 mb-4 cursor-pointer"
              onClick={() => navigate("/profile")}
            />
            <input
              type="file"
              id="profilePicInput"
              ref={profilePicInputRef}
              style={{ display: "none" }}
              onChange={handleProfilePicUpload}
            />
            <h3 className="text-2xl font-poppins">{user?.name}</h3>
            <p className="text-lg">{user?.bio}</p>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-1 rounded-none relative">
          <style>
            {`
      /* Add this CSS for scrollbar customization */
      ::-webkit-scrollbar {
        width: 12px; /* Increased scrollbar width */
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5); /* Color of the scrollbar */
        border-radius: 10px; /* Rounded scrollbar */
      }

      ::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
      }
    `}
          </style>

          {loading && <div className="text-black">Loading...</div>}
          {error && <div>{error}</div>}

          {/* Box for Textual Results */}
          {textualResults && textualResults.length > 0 && (
            <div
              className="bg-slate-700 text-white p-4 rounded-md overflow-y-auto mt-20 md:mt-0"
              style={{
                maxHeight: "250px", // Adjust based on your UI needs
                marginBottom: "1rem",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              {textualResults
                .split("\n")
                .filter((point) => point.trim() !== "")
                .map((point, index) => (
                  <p key={index} className="font-poppins mb-2">
                    {point.replace(/^[0-9]+[.)]\s*/, "").trim()}{" "}
                    {/* Removes any number followed by a period or a parenthesis */}
                  </p>
                ))}
            </div>
          )}

          {/* Show video results only if they exist and are non-empty */}
          {results && results.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {results.map((video, index) => (
                <VideoCard
                  key={index}
                  video={video}
                  query={query}
                  handleVideoPlay={handleVideoPlay}
                />
              ))}
            </div>
          ) : (
            // Display the "no videos" message only if search has been submitted and results are empty
            !loading &&
            searchSubmitted &&
            results &&
            results.length === 0 && (
              <div className="text-black ml-20 font-poppins">
                We currently don't have videos related to this situation or
                please try making your query more specific.
              </div>
            )
          )}

          {/* Feedback Button */}
          <div className="fixed bottom-4 mb-16 right-4 md:bottom-6 md:right-6 z-50">
            <FeedbackForm userId={user?._id} />
          </div>
        </div>

        <div className="hidden md:flex bg-gray-600 opacity-70 w-1/5 min-h-screen p-4 rounded-lg flex-col mt-1 mr-1 mb-1">
          {searchSubmitted && manualSearch && !loading && (
            <div className="mb-4">
              <p className="text-white font-poppins mb-2 ml-8">
                For better results click on:
              </p>
              <button
                className="p-2 bg-slate-900 ml-16 text-white font-poppins rounded-md justify-center"
                onClick={handleRewritePromptClick}
              >
                Rewrite prompt
              </button>
            </div>
          )}

          {/* Show suggestions if available */}
          {showRewritePrompt && threeSuggestions.length > 0 && (
            <div>
              {threeSuggestions.map((suggestion, index) => (
                <button
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="p-2 bg-slate-900 text-white font-poppins rounded-md mb-2 w-full"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold font-poppins text-black mb-4">
              Are you sure?
            </h2>
            <p className="mb-6">Do you want to log out?</p>
            <div className="flex justify-around">
              <button
                onClick={() => setShowLogoutPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                No
              </button>
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white px-4 py-2 rounded-full"
              >
                Yes, Log Out
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reset Password Popup */}
      {showResetPasswordPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold text-black mb-4">
              Reset Password
            </h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Current Password
              </label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-bold mb-2">
                New Password
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="flex justify-around">
              <button
                onClick={() => setShowResetPasswordPopup(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
              >
                Cancel
              </button>
              <button
                onClick={handleResetPassword}
                className="bg-blue-500 text-black px-4 py-2 rounded-full"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
